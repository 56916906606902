<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <EditQuizForm :book_id="book_id" :value="quiz" />
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditQuizForm from '@/components/form/EditQuizForm.vue';

export default {
  components: { BreadCrumb, EditQuizForm },

  data() {
    return {
      book_id: this.$route.params.book_id,
      quiz: {},
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: this.book_id, to: { name: 'bke-book-view', params: { book_id: this.book_id } } },
        { text: 'Quizzes List', to: { name: 'quiz-list', params: { book_id: this.book_id } } },
        { text: 'New', active: true },
      ];
    },
  },
};
</script>
